.wrapper {
  width: 100%;
  padding: 2px;
  > div {
    margin: 5px 20px;
  }
}

h2 {
  text-align: center;
}

h4 {
  text-align: center;
}

.formListWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 200px;
  }
}

.nestedFormItemWrapper {
  display: flex;
  justify-content: flex-start;
}

.job {
  display: "flex";
  margin: 2px;
}

.maintainanceNotice {
  text-align: center;
  > button {
    display: inline-flex;
    justify-content: center;
  }
}

.refreshIcon {
  text-align: right;
  > button {
    display: inline-flex;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .wrapper {
    > div {
      margin: 10px 5px;
    }
  }
}
