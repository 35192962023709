.wrapper {
  display: flex;
  width: 100%;
}
.aside {
  width: 7%;
  min-width: 5%;
  display: flex;
  flex-wrap: wrap;
  background: rgb(66, 62, 62);
  justify-content: center;
  border-radius: 0px 25px 25px 0px;
  > ul {
    padding: 20px 5px;
    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 6px;
      cursor: pointer;
      text-align: center;
    }
  }
  > * {
    position: fixed;
    list-style: none;
  }
}

.content {
  width: 93%;
  padding: 15px;
  min-height: 50vh;
}

.header {
  display: flex;
  > * {
    margin: 5px;
  }
  .searchForm {
    display: flex;
    > * {
      margin: 2px;
    }
  }
}

.completeNewPassword {
  width: fit-content;
  padding: 10px;
  margin: 5px 0px;
  border: 2px solid red;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .header {
    flex-wrap: wrap;
    .searchForm {
      > * {
        margin: 0px;
      }
    }
  }

  .aside > ul > li > div {
    display: none;
  }
}

.crmHeader {
  display: inline-flex;
  align-items: baseline;
}
