.wrapper {
  display: flex;
  // position: fixed;
  padding: 5px;
  // justify-content: center;
  width: 100%;
  // height: 100vh;
  // align-items: center;
  > * {
    margin: 5px;
  }
  > .adminPanel {
    display: flex;
    margin-left: auto;
    > * {
      margin: 5px;
    }
  }
}

.displayName {
  // color: yello;
  margin: 5px;
}
