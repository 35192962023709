@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";
// @import "quill/dist/quill.snow.css";

//ant-table
.ant-table {
  border-radius: 25px !important;
}
.ant-table-wrapper {
  border-radius: 25px !important;
}
.ant-table-container {
  border-radius: 25px !important;
}

.ant-table-header {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.ant-table-body {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.ant-table-tbody {
  border-radius: 25px !important;
}

.ant-pagination-item-active {
  border-color: purple !important;
}

.ant-pagination-item-active > a {
  color: purple !important;
}

.ant-pagination-item:hover {
  border-color: purple !important;
}

.ant-pagination-item:hover a {
  color: purple !important;
}

.ant-pagination-item-link:hover {
  color: purple !important;
  border-color: purple !important;
}

.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: purple;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: purple;
}

//ant-button
.ant-btn {
  border-color: purple;
  // background-color: rgb(44, 42, 42);
}

.ant-btn:hover {
  border-color: purple;
  background-color: whitesmoke;
  color: purple;
}

.ant-btn-primary {
  border-color: black;
  background: purple;
  border-radius: 20px !important;
}

.ant-btn-primary:focus {
  border-color: black;
  background: purple;
  border-radius: 20px !important;
}

.ant-btn-primary:hover {
  background: white;
  color: purple;
}

.ant-btn-ghost {
  border-style: none;
  background: transparent;
  color: purple;
}

.ant-btn-ghost:focus {
  border-style: none;
  background: transparent;
}

.ant-btn-ghost:hover {
  border-style: none;
  background: transparent;
  color: white;
}

// Secondary Button Styles
.ant-btn-secondary {
  border-color: purple;
  background: whitesmoke;
  color: purple;
  border-radius: 20px; /* Optional: Custom radius for secondary buttons */
}

.ant-btn-secondary:focus {
  border-color: purple;
  background: whitesmoke;
  color: purple;
}

.ant-btn-secondary:hover {
  border-color: purple;
  background: lightgray;
  color: purple;
}

.ant-btn-secondary:active {
  border-color: purple;
  background: darkgray;
  color: white;
}

//ant-modal
.ant-modal-content {
  border-radius: 25px !important;
}

//ant-input
.ant-input {
  border-color: purple;
  background-color: rgb(44, 42, 42);
  color: white;
}

.ant-input:hover {
  border-color: purple;
}

.ant-input-affix-wrapper {
  border-color: purple !important;
  background-color: rgb(44, 42, 42);
  color: white;
}

.ant-select-selector {
  border-color: purple !important;
}

//ant-date picker
.ant-picker {
  border-color: purple;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: purple !important;
}
.ant-picker:hover {
  border-color: purple;
}

.ant-picker-cell-inner::before {
  border-color: purple !important;
}

.ant-picker-today-btn {
  color: white !important;
}

.ant-picker-today-btn:hover {
  color: purple !important;
}

//ant-switch
.ant-switch-checked {
  background-color: purple !important;
}

//ant-spin
.ant-spin {
  color: purple !important;
}

//ant-form
.ant-form {
  margin: 2% 3%;
}

@primary-color: #1DA57A;