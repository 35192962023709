.wrapper {
  display: flex;
  width: 100%;
}

.image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  > img {
    border-radius: 30px;
    padding: 20px;
    position: fixed;
    height: 50%;
    width: 50%;
  }
}

.createNewTicket {
  width: 50%;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 600px) {
  .image {
    display: none;
  }
  .createNewTicket {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}
